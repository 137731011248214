.pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin-top: 32px;
    margin-bottom: 104px;
    
    @media screen and (min-width: 850px) {
        padding: 0 25.5px;
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 64px;
    }

    .activeClassName,
    .breakDots {
        font-size: 18px;
        font-family: SourceSansPro-Regular;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        color: var(--orange);
        padding: 4px 16px;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.active {
            border: 1px solid var(--orange);
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .breakDots {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .pagination_buttons {
        padding: 8px 32px;
        border: 1px solid var(--orange);
        color: var(--orange);
        font-size: 18px;
        font-family: SourceSansPro-SemiBold;
        line-height: 28px;
        background-color: var(--white);
        cursor: pointer;

        &__link {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        &__disable {
            border-color: var(--greyDisabled);
            color: var(--greyDisabled);
            cursor: not-allowed;

            #Shape path{
                fill: var(--greyDisabled);
            }
        }

        @media screen and (max-width: 670px) {
            padding: 8px 0;
            border: none
        }
    }

    .prevBtn {
        margin-right: 84px;
        @media screen and (max-width: 850px) {
            margin-right: 54px;
        }
        @media screen and (max-width: 347px) {
            margin-right: 24px;
        }
    }

    .nextBtn {
        margin-left: 84px;
        @media screen and (max-width: 850px) {
            margin-left: 54px;
        }
        @media screen and (max-width: 347px) {
            margin-left: 24px;
        }
    }
}