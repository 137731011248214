$commonPadding: 40px;

.trendingTopics {
    position: relative;
    padding: 40px 0 40px 40px;

    &_header {
        background: var(--light-blue);
        margin-top: calc(67px - $commonPadding);
        margin-bottom: 43px;
        width: 300px;
        transition: all 0.3s;
        filter: drop-shadow(0px 4px 16px rgba(8, 11, 30, 0.16));

        .trendingTopics_heading {
            font-family: SourceSansPro-SemiBold;
            font-size: 20px;
            line-height: 27px;
            color: var(--blue);
            padding: 16px;
            position: relative;
            h3 {
                width: 190px;
            }
            &__seal {
                position: absolute;
                right: -32px;
                top: 50%;
                transform: translateY(-50%);
                width: 140px;
                height: 140px;

                img {
                    width: 140px;
                    height: 140px;
                }
            }
        }
    }

    &_description {
        margin-bottom: 55px;
        p {
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 27px;
            color: var(--bodyText);
        }
    }

    &_articles {
        &__header {
            font-family: SourceSansPro-Bold;
            font-size: 24px;
            line-height: 30px;
            color: var(--bodyText);
            margin-bottom: 16px;
        }
        &__links {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            a {
                font-family: SourceSansPro-Regular;
                font-size: 18px;
                line-height: 28px;
                color: var(--blue);
                text-decoration: none;
                margin-bottom: 4px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .trendingTopics {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 40px 24px;
        .trendingTopics_header {
            margin-right: 75px;
            margin-top: 10px;
        }
        .trendingTopics_articles {
            margin-top: 0;
        }
        .trendingTopics_description {
            order: -1;
            flex: 1 0 100%;
            margin-bottom: 43px;
        }
    }
}

@media screen and (max-width: 568px) {
    .trendingTopics {
        padding: 40px 8px;
        .trendingTopics_header {
            width: 250px;
            margin-right: 115px;
            .trendingTopics_heading {
                padding: 13px;
                h3 {
                    font-size: 18px;
                    width: 162px;
                }
                .trendingTopics_heading__seal {
                    right: -78px;
                }
            }
        }
        .trendingTopics_articles {
            margin-top: 0;
        }
        .trendingTopics_description {
            order: -1;
            flex: 1 0 100%;
            margin-bottom: 43px;
        }
    }
}

@media screen and (max-width: 450px) {
    .trendingTopics {
        .trendingTopics_header {
            .trendingTopics_heading {
                padding-right: 20px;
                .trendingTopics_heading__seal {
                    right: -111px;
                }
            }
        }
    }
}