.webinarsList {
    margin-top: 24px;

    &_header {
        margin-bottom: 40px;

        h2 {
            font-size: 32px;
            font-family: SourceSansPro-SemiBold;
            color: var(--orange);
            margin-bottom: 24px;
        }
        p {
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 28px;
            color: var(--bodyText);
        }
    }

    &_content {
        display: flex;
        flex-direction: row;
        margin-bottom: 100px;
        padding-top: 32px;

        .listContent {
            width: 70%;
            flex-grow: 2;
            margin-right: 40px;
        }

        .webinarsList_items {
            border-top: 1px solid var(--grey);
            .preloader {
                margin-top: 20px;
            }
            .pagination {
                padding: 0;
            }
        }

        .webinarsList_form  {
            flex-basis: 383px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 1080px) {
    .webinarsList {
        &_content {
            flex-direction: column;
            padding-top: 24px;

            .listContent {
                width: 100%;
                margin-bottom: 64px;
            }

            .webinarsList_form {
                flex-basis: auto;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .webinarsList {
        &_header {
            font-size: 24px;
            line-height: 30px;
        }
    }
}