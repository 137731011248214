.careerServicesLink {
    padding: 24px;
    background-color: var(--lightOrange);
    border: 1px solid var(--grey);
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_header {
        font-family: SourceSansPro-SemiBold;
        font-size: 24px;
        line-height: 30px;
        width: 100%;
        margin-bottom: 12px;
    }

    p {
        width: 100%;
        font-family: SourceSansPro-Regular;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 24px;
        text-align: left;
        sup {
            font-size: 12px;
        }
    }

    .careerServices_link {
        padding: 12px 0;
        width: 100%;
        background-color: var(--orange);
        font-family: SourceSansPro-Bold;
        color: var(--white);
        font-size: 16px;
        line-height: 20px;
        outline: none;
        border: none;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
    }

    @media screen and (max-width: 1080px) {
        margin-bottom: 64px;
    }

    @media screen and (max-width: 426px) {
        padding: 16px;
        &_header {
            font-size: 20px;
            line-height: 28px;
        }
    }
}