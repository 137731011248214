.textContent {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &.speakingEngagements {
        margin-bottom: 100px;
        .textContent_paragraph:nth-child(2) {
            margin-bottom: -16px;
            margin-top: -24px;
        }
    }

    @media screen and (max-width: 380px) {
        gap: 24px;
    }

    &_description {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h3 {
            font-size: 24px;
            font-family: 'SourceSansPro-SemiBold';
            color: var(--bodyText);
            margin-top: calc(40px - 16px);

            @media screen and (max-width: 380px) {
                font-size: 20px;
            }
        }
    }

    .categoryTitle {
        font-size: 24px;
        font-family: 'SourceSansPro-SemiBold';

        @media screen and (max-width: 380px) {
            font-size: 20px;
        }
    }

    &_paragraph {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: var(--bodyText);
        font-family: 'SourceSansPro-SemiBold';

        &.speakingEngagements {
            gap: 4px;

            h3 {
                font-size: 20px;
            }

            div {
                gap: 0;
                p {
                    color: var(--bodyText);
                }
            }
        }    

        &.testimonials {
            div {
                display: flex;
                flex-direction: column;
                gap: 16px;
    
                .textContent_paragraph__author {
                    font-style: italic;
                }
            }
        }

        h3 {
            font-size: 24px;
            font-family: 'SourceSansPro-SemiBold';
            color: var(--bodyText);

            @media screen and (max-width: 380px) {
                font-size: 20px;
            }
        }

        h4 {
            font-size: 20px;
            font-family: 'SourceSansPro-SemiBold';
            color: var(--bodyText);

            @media screen and (max-width: 380px) {
                font-size: 18px;
            }
        }

        a {
            color: var(--blue);
            font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: 0px;
        }
        
        div {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    &_footer {
        font-weight: 600 !important;
    }
}