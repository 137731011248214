.autocompleteInput {
    position: relative;

    .rbt {
        div {
            .rbt-input-hint {
                display: none;
            }
        }
    }

    input {   
        width: 100%;
        margin-right: 8px;
        padding: 13px 40px;
        outline: none;
        border: none;
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        color: var(--text2);
        margin-top: 4px;
        margin: 0;

        &::placeholder {
            font-family: SourceSansPro-Regular;
            font-size: 16px;
            color: var(--text2);
        }
    }

    label {
        font-family: SourceSansPro-SemiBold;
        font-size: 16px;
        line-height: 20px;
        color: var(--white);
        margin-bottom: 4px;
    }

    .location_input {
        content: "";
        position: absolute;
        left: 10px;
        top: 34px;
        bottom: 0;
        width: 20px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clip-rule='evenodd' d='M3.05493 8.61113C3.05493 4.77582 6.16407 1.66669 9.99938 1.66669C13.8347 1.66669 16.9438 4.77582 16.9438 8.61113C16.9438 10.9525 15.6922 13.2472 13.6285 15.4255C12.9209 16.1725 12.1648 16.8529 11.4085 17.4552C11.276 17.5607 11.148 17.6601 11.0254 17.7532L10.6745 18.0133L10.3846 18.2167C10.1513 18.3722 9.84743 18.3722 9.61417 18.2167L9.32423 18.0133L8.97333 17.7532C8.85074 17.6601 8.72279 17.5607 8.59023 17.4552C7.83392 16.8529 7.07785 16.1725 6.37024 15.4255C4.3066 13.2472 3.05493 10.9525 3.05493 8.61113ZM15.5544 8.6114C15.5544 5.54315 13.0671 3.05585 9.99883 3.05585C6.93058 3.05585 4.44328 5.54315 4.44328 8.6114C4.44328 10.5235 5.53536 12.5256 7.37797 14.4706C8.03755 15.1668 8.74632 15.8047 9.45486 16.3689C9.57865 16.4675 9.69789 16.5602 9.81181 16.6467L9.99883 16.7864L10.3606 16.5123L10.5428 16.3689C11.2513 15.8047 11.9601 15.1668 12.6197 14.4706C14.4623 12.5256 15.5544 10.5235 15.5544 8.6114Z' fill='%23A8A5A4'/%3e%3cpath fillRule='evenodd' clip-rule='evenodd' d='M7.22168 8.61109C7.22168 7.07697 8.46533 5.83331 9.99946 5.83331C11.5336 5.83331 12.7772 7.07697 12.7772 8.61109C12.7772 10.1452 11.5336 11.3889 9.99946 11.3889C8.46533 11.3889 7.22168 10.1452 7.22168 8.61109ZM11.3878 8.61136C11.3878 7.8443 10.766 7.22247 9.99892 7.22247C9.23185 7.22247 8.61003 7.8443 8.61003 8.61136C8.61003 9.37842 9.23185 10.0003 9.99892 10.0003C10.766 10.0003 11.3878 9.37842 11.3878 8.61136Z' fill='%23A8A5A4'/%3e%3c/svg%3e");          

    }
    .error {
        position: absolute;
        bottom: -37px;
        left: 0;
        color: red;
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        background: rgba(255, 255, 255, 0.5);
        @media screen and (max-width: 611px){
            bottom: -20px;
        }
    }

    .custom-menu-class {
        background: var(--white);
        display: flex!important;
        flex-direction: column;

        position: absolute;
        inset: 0px auto auto 0px;
        display: block;
        max-height: 300px;
        overflow: auto;
        transform: translate3d(0px, 48.5px, 0px);
        width: 100%;
        box-shadow: 0 0 12px 0 rgba(0,0,0,0.07);
        z-index: 10;

        .custom-menu-item,
        .dropdown-item.disabled {
            padding: 10px 20px;
            text-decoration: none;
            cursor: pointer;
            color: var(--text2);
            font-family: SourceSansPro-Regular;
            font-size: 16px;
            overflow: visible;

            &:hover {
                background: var(--medium-blue);
            }
        }
    }

}