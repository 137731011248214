@font-face {
    font-family: 'SourceSansPro-Light';
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
        url(../fonts/SourceSansPro-Light/SourceSansPro-Light.woff)
            format('woff'),
        url(../fonts/SourceSansPro-Light/SourceSansPro-Light.ttf)
            format("truetype");
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'SourceSansPro-Regular';
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
        url(../fonts/SourceSansPro-Regular/SourceSansPro-Regular.woff)
            format('woff'),
        url(../fonts/SourceSansPro-Regular/SourceSansPro-Regular.ttf)
            format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
        url(../fonts/SourceSansPro-SemiBold/SourceSansPro-SemiBold.woff)
            format('woff'),
        url(../fonts/SourceSansPro-SemiBold/SourceSansPro-SemiBold.ttf)
            format("truetype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'SourceSansPro-Bold';
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
        url(../fonts/SourceSansPro-Bold/SourceSansPro-Bold.woff)
            format('woff'),
        url(../fonts/SourceSansPro-Bold/SourceSansPro-Bold.ttf)
            format("truetype");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}