.footerBlue {
    width: 100%;
    background: var(--blue);
    padding: 32px 0 10px 0;
    color: var(--white);
    font-family: 'SourceSansPro-Regular';

    .footerBlue_container {
        display: flex;
        flex-direction: row;
        margin-bottom: 24px;

        .footerBlue_leftside,
        .footerBlue_rightside {
            display: flex;
            flex-direction: row;
        }

        .footerBlue_logo__container {
            width: 219px;
            margin-right: 24px;

            address {
                font-size: 16px;
                line-height: 24px;
                margin-top: 12px;
                width: 159px;
            }
        }

        .footer_links {
            display: flex;
            flex-direction: column;
            width: 463px;
            margin-right: 24px;
            a {
                color: var(--white);
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 4px;
            }
        }

        h4 {
            font-family: 'SourceSansPro-Bold';
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 8px;
        }

        .contactUs {
            display: flex;
            flex-direction: column;
            width: 219px;
            margin-right: 24px;

            .contactUs_phone {
                color: var(--white);
                text-decoration: none;
            }
        }
        .contactSocials {
            width: 219px;

            .socialsLinks {
                img {
                    margin-right: 16.5px;
                }
            }

        }
    }

    .footerCopyright {
        font-size: 16px;
        line-height: 20px;
        color: var(--grey);

        a {
            color: var(--grey);
        }
    }
}

@media screen and (max-width: 1239px) {
    .footerBlue {
        .footerBlue_container {
            flex-direction: column;
            .footerBlue_leftside {
                margin-bottom: 16px;
            }
        }
    }
}

@media screen and (max-width: 568px) {
    .footerBlue {
        .footerCopyright {
            text-align: center;
            padding-bottom: 23px;
        }
        .footerBlue_container {
            align-items: center;

            .footerBlue_leftside,
            .footerBlue_rightside {
                flex-direction: column;
                align-items: center;
                width: 100%;
            }

            .contactUs,
            .contactSocials,
            .footer_links,
            .footerBlue_logo__container {
                width: auto;
                text-align: center;
                margin: 0;
            }

            .footerBlue_logo__container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 16px;
                address {
                    margin: 0;
                    padding: 0;
                    margin-top: 12px;
                    text-align: center;
                }
            }
            .contactUs {
                margin-bottom: 16px;
                h4 {
                    margin-bottom: 8px;
                }
            }
            .contactSocials {
                margin-bottom: 24px;
            }
        }

    }
}