body {
    font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: #0D161F;
}

h1 {
    font-family: 'SourceSansPro-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 40px;
    line-height: 50px;
}

h2 {
    font-family: 'SourceSansPro-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 44px;
}

h3 {
    font-family: 'SourceSansPro-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 32px;
}

h4 {
    font-family: 'SourceSansPro-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 21px;
    line-height: 28px;
}

h5 {
    font-family: 'SourceSansPro-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 28px;
}

strong {
    font-family: 'SourceSansPro-SemiBold', Arial, Helvetica, sans-serif;
}

em {
    font-style: italic;
}

.title-orange {
    font-family: 'SourceSansPro-SemiBold', Arial, Helvetica, sans-serif;
    color: var(--orange);
    font-size: 32px;
    line-height: 44px;
}

@media screen and (max-width: 568px) {
    .title-orange {
        font-size: 24px;
        line-height: 30px;
    }
}